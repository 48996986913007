<div class="centerdata">
    <!-- <button class="centerdata" id="full_screen" (click)='fullscreen_1()'>Full Screen Mode</button> -->
    <h1 class="centerdata">Tree Grid Extended!</h1>  

</div>
<h2>Instructions:</h2>      
<ol>
    <li>Multi Sort: Enable Sorting first then Press Ctrl+click on header to excersise multi sort.</li>
    <li>Freeze all left: after freeze context menu will be removed.</li>
</ol>

<div class="control-section">
    <ejs-treegrid
    [dataSource]='data' #treegrid  [contextMenuItems]="contextMenuItems" 
    allowReordering='true'
    [filterSettings]='filterSettings' 
    [editSettings]='editing'
    [selectionSettings]='selectOptions'
    childMapping='subtasks' height='100%'
    
    [pageSettings]='pageSettings' [treeColumnIndex]='1'>
        <e-columns>
            <e-column field='taskID' headerText='Task ID' width='100' textAlign='Right' ></e-column>
            <e-column field='taskName' headerText='Task Name' width='190'></e-column>
            <e-column field='startDate' headerText='Start Date' width='135' format="yMd" textAlign='Right'></e-column>
            <e-column field='approved' headerText='Approved' width='140' textAlign='Center' type='boolean' displayAsCheckBox='true'></e-column>
            <e-column field='priority' headerText='Priority' width='140'></e-column>
        </e-columns>
    </ejs-treegrid>
</div>
<div>
    <ejs-contextmenu #headercontextmenu target='.e-gridheader'
    [items]='headermenuItems' (select)='select($event)' (beforeOpen)="contextMenuOpen($event)" ></ejs-contextmenu>
</div>

<div>
    <ejs-contextmenu #contextmenu target='.e-content' [items]='menuItems' (select)='select($event)'></ejs-contextmenu>
</div>

<div id='dialog'></div>

<div id="dlgContent" style="visibility: hidden" class="dialogContent">
    <div class="e-dlg-content" id="_gridcontrol_dialogEdit_wrapper_dialog-content">
        <div class="e-insertedrow">
            <form class="e-gridform e-lib e-formvalidator" id="_gridcontrolChildForm" novalidate="">
                <table class="e-table" cellspacing="6px">
                    <tbody>
                        <tr>
                            <td class="e-rowcell" style="text-align:left;width:190px">
                                <div class="e-float-input e-control-wrapper">
                                    <input class="e-field e-defaultcell e-ralign e-control e-textbox e-lib" type="number" value="" style="text-align:Right" id="_gridcontroltaskIDchild" name="taskID" e-mappinguid="grid-column1001" role="textbox" aria-labelledby="label__gridcontroltaskID"><span class="e-float-line"></span><label class="e-float-text e-label-top" id="label__gridcontroltaskID" for="_gridcontroltaskID">Task ID</label></div></td></tr><tr><td class="e-rowcell" style="text-align:left;width:190px"><div class="e-float-input e-control-wrapper">
                                        <input class="e-field e-defaultcell e-control e-textbox e-lib" type="text" value="subtask" style="text-align:undefined" id="_gridcontroltaskName" name="taskName" e-mappinguid="grid-column1002" role="textbox" aria-labelledby="label__gridcontroltaskName"><span class="e-float-line"></span><label class="e-float-text e-label-top" id="label__gridcontroltaskName" for="_gridcontroltaskName">Task Name</label></div></td></tr><tr><td class="e-rowcell" style="text-align:left;width:190px"><div class="e-float-input e-control-wrapper">
                                            <input class="e-field e-defaultcell e-ralign e-control e-textbox e-lib" type="text" value="9/28/2021" style="text-align:Right" id="_gridcontrolstartDate" name="startDate" e-mappinguid="grid-column1003" role="textbox" aria-labelledby="label__gridcontrolstartDate"><span class="e-float-line"></span><label class="e-float-text e-label-top" id="label__gridcontrolstartDate" for="_gridcontrolstartDate">Start Date</label></div></td></tr><tr><td class="e-rowcell" style="text-align:left;width:190px"><div class="e-float-input e-control-wrapper"><input class="e-field e-defaultcell e-control e-textbox e-lib" type="text" value="true" style="text-align:Center" id="_gridcontrolapproved" name="approved" e-mappinguid="grid-column1004" role="textbox" aria-labelledby="label__gridcontrolapproved"><span class="e-float-line"></span><label class="e-float-text e-label-top" id="label__gridcontrolapproved" for="_gridcontrolapproved">Approved</label></div></td></tr>
                                        <tr><td class="e-rowcell" style="text-align:left;width:190px"><div class="e-float-input e-control-wrapper">
                                            <input class="e-field e-defaultcell e-control e-textbox e-lib" type="text" value="High" style="text-align:undefined" id="_gridcontrolpriority" name="priority" e-mappinguid="grid-column1005" role="textbox" aria-labelledby="label__gridcontrolpriority"><span class="e-float-line"></span>
                                            <label class="e-float-text e-label-top" id="label__gridcontrolpriority" for="_gridcontrolpriority">Priority</label>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>

    </div>
    <div class="e-footer-content">
        <button (click)="saveChild()" type="button" class="e-control e-btn e-lib e-primary e-flat" data-ripple="true">Save</button>
        <button (click)="closeChild()" type="button" class="e-control e-btn e-lib e-flat" data-ripple="true">Cancel</button>
    </div>
</div>

    