import {
  Component,
  ElementRef,
  OnInit,
  Inject,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { sampleData } from "./jsontreegriddata";
import {  FreezeService, RowDDService, SortService, ResizeService, PageService, ToolbarService, EditService, TreeGridComponent } from "@syncfusion/ej2-angular-treegrid";
import {
  ContextMenuComponent,
  MenuItemModel,
} from "@syncfusion/ej2-angular-navigations";

import {  EditSettingsModel } from '@syncfusion/ej2-treegrid';
import { freezeDirection,Column } from '@syncfusion/ej2-grids';
import { DOCUMENT } from '@angular/common'; 
import { Dialog } from '@syncfusion/ej2-popups';
import { enableRipple } from '@syncfusion/ej2-base';

enableRipple(true);
@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.css"],
  providers: [ToolbarService, FreezeService, PageService, SortService, ResizeService, EditService, RowDDService]
})
export class AppComponent {
  public data: Object[] = [];
  public filterSettings: any;
  public pageSettings: any;
  public contextMenuItems: any;
  public editing!: EditSettingsModel;
  public isCut = false;
  @ViewChild("treegrid")
  public treegrid!: TreeGridComponent;
  @ViewChild("contextmenu")
  public contextmenu!: ContextMenuComponent;
  @ViewChild("headercontextmenu")
  public headercontextmenu!: ContextMenuComponent;
  headermenuItems: any;
  selectitem: any;
  clickedColumn: any;
  public selectOptions: any;
  // Initialization of Dialog
  public dialog: Dialog ;
  public menuItems: MenuItemModel[] = [
    {
        text: 'Add Next',
        iconCss: 'e-icons e-add'
    },
    {
      text: 'Add Child',
      iconCss: 'e-icons e-add'
    },
    {
        text: 'Edit Row',
        iconCss: 'e-icons e-edit'
    },
    {
        text: 'Delete Row',
        iconCss: 'e-icons e-trash'
    },
    {
      text: 'Cut',
      iconCss: 'e-icons e-cut'
    },
    {
        text: 'Copy',
        iconCss: 'e-icons e-copy'
    },
    {
        text: 'PasteNext',
        iconCss: 'e-icons e-paste',
    }];

  constructor(@Inject(DOCUMENT) document: any, private ren: Renderer2, private eleRef: ElementRef) {}
  fullscreen_1(): void{
    document.body.requestFullscreen();  
  }

  
  ngOnInit(): void {
    localStorage.setItem("lastcount","1003");
    this.dialog= new Dialog({
      // Set Dialog position
    // position: { X: 'center', Y: 'center' },
    
      position: { X: "center", Y: "center" },
      isModal:true,
      // overlayClick event handler
      overlayClick: onOverlayClick,
       // Enables the header
       header: 'Add New Child Record',
       // Enables the close icon button in header
       showCloseIcon: true,
       // Dialog content
       content: document.getElementById("dlgContent"),
       // The Dialog shows within the target element
       target: document.getElementById("control-section"),
       // Dialog width
       width: '300px',
       height: '500px',
       beforeOpen: onBeforeopen
    });
    
    function onOverlayClick() {
      this.dialog.hide();
    }
    function onBeforeopen(): void {
        document.getElementById('dlgContent').style.visibility = 'visible';
    }
    // Render initialized Dialog
    this.dialog.appendTo('#dialog');
    this.dialog.hide();
    // this.dialog.appendTo('#dialog');
    this.data = sampleData;
    this.pageSettings = { pageSize: 1000 };


    this.editing = {  allowAdding: true, allowDeleting: true, allowEditing: true, mode: 'Dialog' , newRowPosition: 'Below' };    
    this.selectOptions = { type: 'Multiple' };
    this.headermenuItems = [
      //Font size
      {
        text: "Font size",
      },
      {
        text: "Text Wrap",
      },
      {
        text: "Font color",
      },
      {
        text: "Background color",
      },
      {
        text: "Column Type",
      },
      {
        text: "Column Align",
      },
      {
        text: "Column Add",
      },
      {
        text: "Column Edit",
      },
      {
        text: "Column minimum Width",
      },
      {
        text: "Enable Freeze",
      },
      {
        text: "Column Delete",
      },
      {
        text: "Enable Filter",
      },
      {
        text: "Enable Sorting",
      },
      {
        text: "Enable Multi Sorting",
      },
      {
        text: "Enable Resizing",
      },
      {
        text: "Enable Drag/Drop",
      },
      
      {
        text: "Show/Hide Columns",
        items:[
          {
            text: "taskID",
          },
          {
            text: "taskName",
          },
          {
            text: "startDate",
          },
          {
            text: "approved",
          },
          {
            text: "priority",
          },
        ]
      },
    ];

    
    this.filterSettings = {
      type: "FilterBar",
      hierarchyMode: "Parent",
      mode: "Immediate",
    };
  }

  contextMenuOpen (arg?: any): void {
    let elem: Element | null = arg.event.target as Element;
    this.clickedColumn = elem.textContent;
    console.log(elem.textContent);
    return;
    // let row: Element | null = elem.closest('.e-row');
    // let uid: string | null = row && row.getAttribute('data-uid');
    // let items: Array<HTMLElement> = [].slice.call(document.querySelectorAll('.e-menu-item'));
    // for (let i: number = 0; i < items.length; i++) {
    //   items[i].setAttribute('style','display: none;');
    // }
}
  select(args: any): void {
    // alert(args.item.text);
    let menu = document.getElementsByClassName("e-table")
    // console.log(menu)
    // this.treegrid.addRecord();
    var rowid = this.treegrid.selectedRowIndex;
    this.selectitem = args.item.text;
      //var person = prompt("Please enter your name", "Harry Potter");
      if(args.item.text === 'Column Add') {
        var newcol = "";
        newcol = prompt("Please enter column name", "test");
        var newCols = [];
        this.treegrid.columns.forEach((res)=>{
          console.log(res.field)
          newCols.push({field: res.field, headerText:res.headerText, width: 70, textAlign: 'Right' });
          
        });
        console.log(newCols)
        // old.push(added)
        newCols.push({ field: newcol, headerText: newcol, width: 80, textAlign: 'Right' });
        this.treegrid.columns = newCols;

      this.treegrid.refreshColumns();

      }
      
      if(args.item.text === 'Column Edit') {
        // alert(this.clickedColumn)
        var newcol = "";
        newcol = prompt("Please enter column name", this.clickedColumn);
        var newCols = [];
        this.treegrid.columns.forEach((res)=>{
          console.log(res.field)
          if(this.clickedColumn == res.headerText){
            newCols.push({field: newcol, headerText:newcol, width: 70, textAlign: 'Right' });
          }else{
            newCols.push({field: res.field, headerText:res.headerText, width: 70, textAlign: 'Right' });
          }
          
          
        });
        
        console.log(newCols)
        this.treegrid.columns = newCols;

      }

      if(args.item.text === 'Column Align') {
        // alert(this.clickedColumn)
        var newcol = "";
        newcol = prompt("Please enter column align", "Right/Left/Center");
        var newCols = [];
        this.treegrid.columns.forEach((res)=>{
          console.log(res.field)
          if(this.clickedColumn == res.headerText){
            newCols.push({field: res.field, headerText:res.headerText, width: 70, textAlign: newcol});
          }else{
            newCols.push({field: res.field, headerText:res.headerText, width: 70, textAlign: 'Right' });
          }
          
          
        });
        
        console.log(newCols)
        this.treegrid.columns = newCols;

      }

      if(args.item.text === 'Font size') {
        // alert(this.clickedColumn)
        var newcol = "";
        newcol = prompt("Please enter column font-size", "");
        var targeted_col_index = 0;
        var i=0;
        this.treegrid.columns.forEach((res)=>{
          console.log(res.field)
          if(this.clickedColumn == res.headerText){
            targeted_col_index = i;
            // newCols.push({field: res.field, headerText:res.headerText, width: 70, textAlign: newcol});
          }
          i++;
        });
        // alert(targeted_col_index)
        console.log(newCols);
        var total_rows = this.treegrid.getRows().length;
        // alert(total_rows);
        for(var j=0;j<total_rows;j++){
          (document.getElementsByClassName('e-rowcell')[targeted_col_index] as any).style = "font-size: "+newcol+"px;";
          targeted_col_index= targeted_col_index+5;
          // (document.getElementsByClassName('e-rowcell')[1] as any).style = "background-color: green";
        }
        
        // this.treegrid.columns = newCols;

      }

      if(args.item.text === 'Font color') {
        // alert(this.clickedColumn)
        var newcol = "";
        newcol = prompt("Please enter column font-color", "");
        var targeted_col_index = 0;
        var i=0;
        this.treegrid.columns.forEach((res)=>{
          console.log(res.field)
          if(this.clickedColumn == res.headerText){
            targeted_col_index = i;
            // newCols.push({field: res.field, headerText:res.headerText, width: 70, textAlign: newcol});
          }
          i++;
        });
        // alert(targeted_col_index)
        console.log(newCols);
        var total_rows = this.treegrid.getRows().length;
        // alert(total_rows);
        for(var j=0;j<total_rows;j++){
          (document.getElementsByClassName('e-rowcell')[targeted_col_index] as any).style = "color: "+newcol;
          targeted_col_index= targeted_col_index+5;
          // (document.getElementsByClassName('e-rowcell')[1] as any).style = "background-color: green";
        }
      }

      if(args.item.text === 'Background color') {
        // alert(this.clickedColumn)
        var newcol = "";
        newcol = prompt("Please enter column background-color", "");
        var targeted_col_index = 0;
        var i=0;
        this.treegrid.columns.forEach((res)=>{
          console.log(res.field)
          if(this.clickedColumn == res.headerText){
            targeted_col_index = i;
            // newCols.push({field: res.field, headerText:res.headerText, width: 70, textAlign: newcol});
          }
          i++;
        });
        // alert(targeted_col_index)
        console.log(newCols);
        var total_rows = this.treegrid.getRows().length;
        // alert(total_rows);
        for(var j=0;j<total_rows;j++){
          (document.getElementsByClassName('e-rowcell')[targeted_col_index] as any).style = "background-color: "+newcol;
          targeted_col_index= targeted_col_index+5;
          // (document.getElementsByClassName('e-rowcell')[1] as any).style = "background-color: green";
        }
      }
      // /normal|break-word|initial|inherit
      if(args.item.text === 'Text Wrap') {
        // alert(this.clickedColumn)
        var newcol = "";
        newcol = prompt("Please enter column text-wrap", "normal|break-word|initial|inherit");
        var targeted_col_index = 0;
        var i=0;
        this.treegrid.columns.forEach((res)=>{
          console.log(res.field)
          if(this.clickedColumn == res.headerText){
            targeted_col_index = i;
            // newCols.push({field: res.field, headerText:res.headerText, width: 70, textAlign: newcol});
          }
          i++;
        });
        // alert(targeted_col_index)
        console.log(newCols);
        var total_rows = this.treegrid.getRows().length;
        // alert(total_rows);
        for(var j=0;j<total_rows;j++){
          (document.getElementsByClassName('e-rowcell')[targeted_col_index] as any).style = "word-wrap: "+newcol;
          targeted_col_index= targeted_col_index+5;
          // (document.getElementsByClassName('e-rowcell')[1] as any).style = "background-color: green";
        }
      }
      //Text/Num/Date/Boolean/DropDownList
      if(args.item.text === 'Column Type') {
        // alert(this.clickedColumn)
        var newcol = "";
        newcol = prompt("Please enter column Type", "Text/Num/Date/Boolean/DropDownList");
        var newCols = [];
        this.treegrid.columns.forEach((res)=>{
          console.log(res.field)
          if(this.clickedColumn == res.headerText){
            newCols.push({field: res.field, headerText:res.headerText, width: 80, textAlign: 'Right', type:newcol });
          }else{
            newCols.push({field: res.field, headerText:res.headerText, width: 70, textAlign: 'Right' });
          }
          
          
        });
        
        console.log(newCols)
        // old.push(added)
        // newCols.push({ field: newcol, headerText: newcol, width: 80, textAlign: 'Right' });
        this.treegrid.columns = newCols;

      }
      // if(args.item.text === 'Column minimum Width') {
      //   // alert(this.clickedColumn)
      //   var newcol = "";
      //   newcol = prompt("Please enter column text-wrap", "100");
      //   var targeted_col_index = 0;
      //   var i=0;
      //   this.treegrid.columns.forEach((res)=>{
      //     console.log(res.field)
      //     if(this.clickedColumn == res.headerText){
      //       targeted_col_index = i;
      //       // newCols.push({field: res.field, headerText:res.headerText, width: 70, textAlign: newcol});
      //     }
      //     i++;
      //   });
      //   (document.getElementsByClassName('e-headercell')[targeted_col_index] as any).style = "min-width: "+newcol+"px";

      // }
      if(args.item.text === 'Column minimum Width') {
        // alert(this.clickedColumn)
        var newcol = "";
        newcol = prompt("Please enter column min width", "200");
        var newCols = [];
        this.treegrid.columns.forEach((res)=>{
          console.log(res.field)
          if(this.clickedColumn == res.headerText){
            newCols.push({field: res.field, headerText:res.headerText, width: newcol, textAlign: 'Right' });
          }else{
            newCols.push({field: res.field, headerText:res.headerText, width: 70, textAlign: 'Right' });
          } 
        });
        
        console.log(newCols)
        // old.push(added)
        // newCols.push({ field: newcol, headerText: newcol, width: 80, textAlign: 'Right' });
        this.treegrid.columns = newCols;

      }
      if(args.item.text === 'Column Delete') {

        var newCols = [];
        this.treegrid.columns.forEach((res)=>{
          console.log(res.field)
          if(this.clickedColumn == res.headerText){
            // newCols.push({field: newcol, headerText:newcol, width: 70, textAlign: 'Right' });
          }else{
            newCols.push({field: res.field, headerText:res.headerText, width: 70, textAlign: 'Right' });
          }
          
          
        });
        
        console.log(newCols);
        this.treegrid.columns = newCols;

      }
      if(args.item.text === 'Print Preview') {
        this.treegrid.print();
      }
      if(args.item.text === 'Add Next') {
        this.editing = {  allowAdding: true, allowDeleting: true, allowEditing: true, mode: 'Dialog' , newRowPosition: 'Below' }; 
        this.treegrid.addRecord();
        // this.treegrid.;
      }
      if(args.item.text === 'Add Child') {
        let selRow = this.treegrid.getSelectedRows();
        //  alert(selRow.length)
        if(selRow.length == 0 ||selRow.length < 0 || selRow.length >1){
          alert("Please select a row first!");
          return;
        }
        let temp = <any[]>this.treegrid.dataSource;
        let count = parseInt(localStorage.getItem("lastcount"))+1;//Math.floor(Math.random() * (9999 - 1004) + 1004);//temp.length+1;
        (<HTMLInputElement>document.getElementById("_gridcontroltaskIDchild")).value =count+"";
        localStorage.setItem("lastcount",count+"")
        this.dialog.show()
        // this.editing = {  allowAdding: true, allowDeleting: true, allowEditing: true, mode: 'Row' , newRowPosition: 'Child' }; 
        // this.treegrid.addRecord();
        // this.treegrid.;
      }
      if(args.item.text === 'Edit Row') {
        if(rowid < 0){
          alert("Please select row first!");
          return;
        }
        // var rowData = this.treegrid.getRowByIndex(rowid);
        var allRows =this.treegrid.getRows()
        var selRow = allRows[rowid];
        this.treegrid.startEdit(selRow);
        // this.treegrid.updateRow(rowid,rowData);
      }
      if(args.item.text === 'Delete Row') {
        if(rowid < 0){
          alert("Please select row first!");
          return;
        }
        alert(rowid.toString());

        var allselRows = this.treegrid.getSelectedRows();
        allselRows.forEach((rec)=>{
            // console.log(rec.ar)
            rec.remove()
            // this.treegrid.deleteRow(rec)
        })
        // this.treegrid.getRows(
        // this.treegrid.remove(rowid.toString());
      }
      if(args.item.text === 'taskID') {
        //alert("I am here")
        var taskID = this.treegrid.getColumnByField('taskID');
        if(taskID.visible){
          taskID.visible = false;
        }
        else{
          taskID.visible = true;
        }
         this.treegrid.refreshColumns();
       }

       if(args.item.text === 'priority') {
        //alert("I am here")
        var priority = this.treegrid.getColumnByField('priority');
        if(priority.visible){
          priority.visible = false;
        }
        else{
          priority.visible = true;
        }
         this.treegrid.refreshColumns();
       }

       if(args.item.text === 'approved') {
        //alert("I am here")
        var approved = this.treegrid.getColumnByField('approved');
        if(approved.visible){
          approved.visible = false;
        }
        else{
          approved.visible = true;
        }
         this.treegrid.refreshColumns();
       }

       if(args.item.text === 'startDate') {
        //alert("I am here")
        var startDate = this.treegrid.getColumnByField('startDate');
        if(startDate.visible){
          startDate.visible = false;
        }
        else{
          startDate.visible = true;
        }
         this.treegrid.refreshColumns();
       }

       if(args.item.text === 'taskName') {
        // this.treegrid.showColumnChooser = true;
        // this.treegrid.openColumnChooser();
        // return;
        //alert("I am here")
        var taskName = this.treegrid.getColumnByField('taskName');
        if(taskName.visible){
          taskName.visible = false;
        }
        else{
          taskName.visible = true;
        }
         this.treegrid.refreshColumns();
       }
       if(args.item.text === 'UnHide Column') {
         this.treegrid.getColumnByField('ShipCountry').visible = true;
         this.treegrid.refreshColumns();
       }
       if(args.item.text === 'Column background color') {
         (document.getElementsByClassName('e-headercell')[1] as any).style = "background-color: green";
       }
       if(args.item.text === 'Reset color') {
         (document.getElementsByClassName('e-headercell')[1] as any).style.cssText = "";
       }
       
      if(args.item.text === 'Enable Resizing'){
        this.treegrid.allowResizing = true;
      } 
      if(args.item.text === 'Enable Drag/Drop'){
        this.treegrid.allowRowDragAndDrop = true;
      } 
      if(args.item.text === 'Enable Filter'){
        this.treegrid.allowFiltering = true;
      } 
      if(args.item.text === 'Enable Sorting'){
        this.treegrid.allowSorting = true;
      } 
      //Enable Multi Sorting
      if(args.item.text === 'Enable Multi Sorting'){
        this.treegrid.allowMultiSorting = true;
      } 
      if(args.item.text === 'Enable Freeze'){
        let mvblColumns: Column[] = this.treegrid.grid.getMovableColumns();
        if (mvblColumns.length === 1 ){
          alert('Atleast one Column should be in movable');
          return;
        }
        //set freez column limit
        this.treegrid.frozenColumns = (this.treegrid.columns.length -1);
        
        let targeted_col_index = 0;
        let i=0;
        this.treegrid.columns.forEach((res)=>{
          // console.log(res.field)
          if(this.clickedColumn == res.headerText){
            targeted_col_index = i;
          }
          i++;
        });
        let j=0;
        this.treegrid.columns.forEach((res)=>{
          // console.log(res.field)
          if(j<=targeted_col_index){
            this.treegrid.grid.getColumnByField(res.field).freeze = ("Left" as freezeDirection);    
          }
          j++;
          
        });
        setTimeout(() => {
          this.treegrid.refreshColumns();  
          // this.treegrid.refresh();  
        }, 100);
      } 
      if(args.item.text === 'TaskID' || args.item.text === 'Priority' || args.item.text === 'Approved' ||
      args.item.text === 'StartDate'|| args.item.text === 'TaskName'
      ) 
      {
        
        // this.clickedColumn = this.clickedColumn.replace(/\s/g, '');
        // alert(this.clickedColumn)
        this.treegrid.frozenColumns = 4
        var task = args.item.text;
        task  = task.replace(/^.{1}/g, task[0].toLowerCase());
        let mvblColumns: Column[] = this.treegrid.grid.getMovableColumns();
        if (mvblColumns.length === 1 ){
          alert('Atleast one Column should be in movable');
          return;
        }
        if(this.treegrid.grid.getColumnByField(task).freeze == "Left"){
          this.treegrid.grid.getColumnByField(task).freeze = ("Center" as freezeDirection);    
        }
        else{
          this.treegrid.grid.getColumnByField(task).freeze = ("Left" as freezeDirection);    
        }  
        setTimeout(() => {
          this.treegrid.refreshColumns();  
        }, 100);
        
       }
       if(args.item.text === 'Copy') {
        this.isCut =false;
        // navigator.clipboard.writeText("")
        var allselRows = this.treegrid.getSelectedRows();
        // console.log(allselRows);
        
        this.treegrid.copy();
        //  (document.getElementsByClassName('e-headercell')[1] as any).style.cssText = "";
       }
       if(args.item.text === 'Cut') {
        var allselRows = this.treegrid.getSelectedRows();
        this.isCut =true;
        // console.log(allselRows);
        this.treegrid.copy();
        allselRows.forEach((rec)=>{
          // console.log(rec)
          rec.remove()
          // this.treegrid.deleteRow()
        });
        let temp = <any[]>this.treegrid.dataSource;
        // let rowid = this.treegrid.selectedRowIndex;
        // alert(rowid)
        let actualrow:any = (this.treegrid.getRowInfo(this.treegrid.getRowByIndex(rowid)));
        actualrow = actualrow.rowData;
        let actualindex = temp.findIndex(item => {
          // console.log(item.taskID +"=="+ actualrow.taskID)
          return item.taskID == actualrow.taskID
        });
        // alert(actualindex)
        actualindex = actualindex -1;
        temp.splice(actualindex,1)
        this.treegrid.dataSource = temp;
        // console.log(temp);
        // console.log(this.treegrid.dataSource);
        this.treegrid.refresh();
        // this.treegrid.remove
        // (document.getElementsByClassName('e-headercell')[1] as any).style.cssText = "";
      }
      if(args.item.text === 'PasteNext') {
        
        var temp = <any[]>this.treegrid.dataSource;

        navigator.clipboard.readText()
        .then(text => {
          // console.log(text);
          let data = text.split('\n')
          // console.log(data);
          for(let i=0;i<data.length;i++){
            let rec = data[i].split("\t");
            let newRecord = {
              taskID: parseInt(rec[0]),
              taskName: rec[1],
              startDate: rec[2],
              approved: (rec[3])?true:false,
              priority: rec[4]
              
            }
            // newData.push(newRecord);
            temp.push(newRecord)
          };
          // console.log(newData)
          // temp.push(newData)
          this.treegrid.dataSource = temp;
          this.treegrid.refresh();
          // console.log(this.treegrid.dataSource)
        })
        .catch(err => {
          console.error('Failed to read clipboard contents: ', err);
        });
      }
  }
  traverse(obj:any, taskID, newChild) {
  
    if(obj.subtasks){
      let o  = obj.subtasks;
      for (var i in o) {
        if (!!o[i] && typeof(o[i])=="object") {
            // console.log("going in depth")
            
            if(o[i].taskID == taskID){
              if(o[i].subtasks){
                o[i].subtasks.push(newChild);
              }else{
                o[i].subtasks= [];
                o[i].subtasks.push(newChild);
              }
              
              
            }else{
              this.traverse(o[i], taskID, newChild);
            }
            // console.log(i, o[i]);
        } else {
            // // console.log("end I guess")
            // console.log(i, o[i]);
        }
      }
    }else{
      obj.subtasks= [];
      obj.subtasks.push(newChild);
    }
    
  }
  saveChild(): void{
    
    var elements = (<HTMLFormElement>document.getElementById("_gridcontrolChildForm")).elements
    var temp = <any[]>this.treegrid.dataSource;

    var obj:any ={};
    for(var i = 0 ; i < elements.length ; i++){
        var item = <HTMLInputElement>elements.item(i);
        obj[item.name] = item.value;
    }
    console.log(obj)
    obj.taskID =  parseInt(obj.taskID);
    var rowid = this.treegrid.selectedRowIndex;
    let actualrow:any = (this.treegrid.getRowInfo(this.treegrid.getRowByIndex(rowid)));
    let newRow:any = {};
    if(actualrow.rowData.hasChildRecords && actualrow.rowData.level == 0){
      newRow = actualrow.rowData.taskData;
      newRow.subtasks.push(obj);
      temp[rowid] = newRow;
    }
    else if(actualrow.rowData.parentItem){      
      
      //complex case
      let actualindex =  rowid;
      // alert('{"taskID":'+actualrow.rowData.taskID);
      temp.forEach((rec,ind)=>{
        // console.log(JSON.stringify(rec))
        if(JSON.stringify(rec).includes('{"taskID":'+actualrow.rowData.taskID)){
          actualindex = ind;
          return 0;
        }
        return -1;
      });
      // alert(actualindex);
      this.traverse(temp[actualindex], actualrow.rowData.taskID, obj)
      // temp[actualindex]
      
    }
    else{
      actualrow = actualrow.rowData;
      let actualindex = temp.findIndex(item => {
        // console.log(item.taskID +"=="+ actualrow.taskID)
        return item.taskID == actualrow.taskID
      });
      // alert(actualindex);
      temp[actualindex] = {...temp[actualindex],subtasks: [obj]}
    }
    // /selRow[0].getAttribute('ariaRowIndex')
    // console.log(rowid);
    // console.log(actualrow);
    // console.log(temp);
    // return;
    // temp[rowid] = {...temp[rowid],subtasks: [obj]}
    // console.log(temp[rowid]);
    this.treegrid.dataSource = temp;
    this.treegrid.refresh();
    this.dialog.hide();
  }

  closeChild(): void{
    this.dialog.hide();
  }
}
