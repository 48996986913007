import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import {
  ContextMenuComponent,
  ContextMenuModule,
  MenuEventArgs,
  MenuItemModel,
} from "@syncfusion/ej2-angular-navigations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { DropDownListModule } from "@syncfusion/ej2-angular-dropdowns";

// import {DragDropModule} from '@angular/cdk/drag-drop';

import {
  ColumnChooserService,
  ColumnMenuService,
  ContextMenuService,
  EditService,
  ExcelExportService,
  PdfExportService,
  ReorderService,
  ResizeService,
  ToolbarService,
  TreeGridModule,
} from "@syncfusion/ej2-angular-treegrid";
import {
  PageService,
  SortService,
  FilterService,
} from "@syncfusion/ej2-angular-treegrid";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TreeGridModule,
    DropDownListModule,
    ContextMenuModule,
  ],
  providers: [
    FilterService,
    PageService,
    SortService,
    ResizeService,
    ColumnMenuService,
    ReorderService,
    ContextMenuService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
